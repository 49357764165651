import { GlobalStyle } from "config";

import { Toaster } from "react-hot-toast"
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'

import 'tailwindcss/tailwind.css'
import Layout from "../components/Layout/Layout";

function MyApp({ Component, pageProps }) {
    return (
        <>
            <GlobalStyle />
            <Toaster />

            <link rel="stylesheet" href="/global.css" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />

            <ChakraProvider>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </ChakraProvider>
        </>
    );
}
export default MyApp;
