
import React from "react"
import styled from "styled-components"

import {getCookie, removeCookies} from 'cookies-next';

import { useRouter } from "next/router"
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
    background: #F8FAFC;
`
const Top = styled.div`
  background: #1A427E;
    margin-bottom: 16px;

`

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
    padding: 16px 0;
  width: 100%;
`
const Content = styled.div``
const Main = styled.div``

const Title = styled.h1`
  margin-top: 50px;
  font-weight: bold;
`

const Button = styled.div`
  padding: 10px;
  font-size: 14px;
  color: white;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
`
const LogoWrap = styled.div`
  display: flex;
  align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;

  img {
    margin-right: 10px;
  }
`

export default function Layout({className, children}: { className?: string; children?: React.ReactNode}) {
  const router = useRouter();

  const loggedIn = getCookie("@vrachtscan%3Auser")
  function logout() {
    removeCookies("@vrachtscan%3Auser")
    router.push("/auth")
  }

  return (
    <Wrapper className={className}>
      <Top>
        <Container>
          <Nav>
              <LogoWrap>
                <img alt="Logo" src="/assets/vrachtscan_avatar.svg" />
              <span>Vrachtscan Viewer</span>
              </LogoWrap>

              {loggedIn && <Button onClick={() => logout()}>Uitloggen</Button>}
          </Nav>
        </Container>
      </Top>
      <Content>
        <Container>
          <Main>
            {children}
          </Main>
        </Container>
      </Content>
    </Wrapper>
  )
}
